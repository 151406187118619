
import {onActivated, ref} from 'vue';
import {
  deleteArticle,
  exportArticlesExcel,
  exportArticlesMarkdown,
  getArticlesAdmin,
  getCategories
} from '@/api/article';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {ElMessage, ElMessageBox} from 'element-plus';
import {download} from "@/api/download";
import fileDownload from "js-file-download";

export default {
  setup(){
        onActivated(() => {
            listArticles();
        });
        const store = useStore();

        const router = useRouter();

        const formSize = ref('large');

        const exporting = ref(false);

        const exportFormVisible = ref(false);

        const getVisibleDict = (visible) => {
            let label = '';
            let type = '';
            if(visible == 1){
                label = '公开';
                type = 'success';
            }else{
                label = '私密';
                type = 'warning';
            }
            return {"label": label,"type": type};
        };


        const articleVisible = [
            {
                visible: 1,
                label: '公开',
            },
            {
                visible: 0,
                label: '私密',
            }
        ];
        //适配移动端
        if(store.state.app.isMobile){
            formSize.value = 'mini';
        }else{
            formSize.value = 'large';
        }

        const table:any = ref({
            page: 1,        // 当前在第几页
            pageSize: 30,   // 一页显示多少
            total: 0,       // 数据总量
            loading: false,  // 加载中
            data: [],       // 表格数据
            isMobile: false,// 表格是否移动端
        });

        const searchParams: any = ref({
            title: '',
            summary: '',
            categoryId: null,
            visible: null,
        });

        const categories: any = ref([]);

        getCategories().then((res) => {
            categories.value = res.data;
        });

        table.value.isMobile = store.state.app.isMobile;

        const listArticles = () => {
            table.value.loading = true,
            getArticlesAdmin({
                page: table.value.page,
                pageSize: table.value.pageSize,
            }).then((res: any) => {
                table.value.data = res.data.list;
                table.value.total = res.data.total;
                table.value.loading = false;
            });
        }

        const handleSizeChange = (pageSize: number) => {
            table.value.pageSize = pageSize;
            listArticles();
        }

        const handleCurrentChange = (page: number) => {
            table.value.page = page;
            listArticles();
        }

        const searchArticles = () => {
            table.value.loading = true,
            getArticlesAdmin({
                page: table.value.page,
                pageSize: table.value.pageSize,
                title: searchParams.value.title,
                summary: searchParams.value.summary,
                categoryId: searchParams.value.categoryId,
                visible: searchParams.value.visible,

            }).then((res: any) => {
                table.value.data = res.data.list;
                table.value.total = res.data.total;
                table.value.loading = false;
            });
        }

        const handleDelete = (id) => {
            deleteArticle(id).then((res) => {
            if(res.data) {
              ElMessage.success("删除成功!");
              listArticles();
            }
            });
        }

        const handleEditClick = (id) => {
            router.push('/articles/publish/' + id);
        }

        const handleExportExcel = () => {
          ElMessageBox.confirm('是否导出？', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function() {
            exportFormVisible.value = false;
            exporting.value = true;
            exportArticlesExcel().then((res: any) => {
              if(res.data) {
                const path = res.data;
                download(path).then((blob: any) => {
                  fileDownload(blob,new Date().getTime() + "_文章列表.xlsx");
                  exporting.value = false;
                }).catch(() => {
                  exporting.value = false;
                });
              }
            }).catch(() => {
              exporting.value = false;
            });
          });
        };

        const handleExportMarkdown = () => {
          ElMessageBox.confirm('是否导出？', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function() {
            exportFormVisible.value = false;
            exporting.value = true;
            exportArticlesMarkdown().then((res: any) => {
              if(res.data) {
                const path = res.data;
                download(path).then((blob: any) => {
                  fileDownload(blob,new Date().getTime() + "_文章导出.zip");
                  exporting.value = false;
                }).catch(() => {
                  exporting.value = false;
                });
              }
            }).catch(() => {
              exporting.value = false;
            });
          });
        }


        return {
            table,
            handleSizeChange,
            handleCurrentChange,
            searchParams,
            categories,
            searchArticles,
            formSize,
            articleVisible,
            getVisibleDict,
            handleDelete,
            handleEditClick,
            handleExportExcel,
            handleExportMarkdown,
            exporting,
            exportFormVisible
            };
        }
}
